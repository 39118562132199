<template>
  <div>
    <Heard />
    <!-- <Tip /> -->
    <div class="container person-box">
      <Aside />
      <div class="right-box">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="手机号">
            <p>{{ user.mobile }}</p>
          </el-form-item>
          <el-form-item label="*验证码" prop="msgcode">
            <el-input
              type="text"
              v-model="ruleForm.msgcode"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="*新密码" prop="pass">
            <el-input
              type="password"
              v-model="ruleForm.pass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="*确认新密码" prop="checkPass">
            <el-input
              type="password"
              v-model="ruleForm.checkPass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <span class="getcode" v-show="show" @click="getCode">获取验证码</span>
          <span v-show="!show" class="count getcode">{{ count }} 秒</span>
          <el-form-item>
            <div class="btn" @click="submitForm('ruleForm')">保存修改</div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Heard from "@/components/Heard.vue";
import { getUser, removeUser } from "@/plugins/auth.js";
import { api_sendMsg, api_updateUser } from "@/apis/apis";
// import Tip from "@/components/Tip.vue";
import Aside from "@/components/Aside.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Heard,
    // Tip,
    Aside,
    Footer,
  },
  data() {
    var checkmsgcode = (rule, value, callback) => {
      if (value == "") {
        return callback(new Error("验证码不能为空"));
      } else {
        if (this.ruleForm.msgcode != this.msgcode) {
          callback(new Error("验证码错误"));
        }
        callback();
      }

      // setTimeout(() => {
      //   if (value != 18) {
      //     callback(new Error("请输入正确密码"));
      //   } else {
      //     callback();
      //   }
      // }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_]{6,12}$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请输入6-12位字母数字结合的密码"));
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass: "",
        checkPass: "",
        code: "",
        msgcode: "",
      },
      msgcode: "",

      user: {},
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        msgcode: [{ validator: checkmsgcode, trigger: "blur" }],
      },
      show: true,
      count: 1,
    };
  },
  created() {
    this.user = getUser();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(1);
          api_updateUser({
            id: this.user.id,
            mobile: this.user.mobile,
            password: this.ruleForm.checkPass,
            msgCode: this.msgcode,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                showClose: true,
                message: "密码重置成功，请重新登录",
                type: "success",
              });
              this.$router.push("/login");
              removeUser();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      api_sendMsg({
        mobile: this.user.mobile,
        msgType: 5,
      }).then((res) => {
        console.log(res);
        this.msgcode = res.data.data.code;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.person-box {
  display: flex;
  .right-box {
    flex: 1;
    border: 1px solid #f2f2f2;
    .el-form {
      width: 390px;
      margin-top: 60px;
      margin-left: 80px;
      margin-bottom: 100px;
      position: relative;
      .btn {
        height: 36px;
        width: 110px;
        line-height: 36px;
        text-align: center;
        margin-left: 100px;
        background-color: #2d69f9;
        color: #fff;
      }
    }
    .getcode {
      position: absolute;
      top: 74px;
      right: 30px;
      font-size: 14px;
      color: #2d69f9;
    }
  }
}
</style>